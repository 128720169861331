import React from "react";

import {
  CardSidebar,
  Block,
  TitleSidebar,
  TitlePost,
  Date,
  CatList,
  CatListItem,
} from "../../components/Sidebar";

import InputSearch from "../../components/InputSearch";

const Sidebar = () => {
  return (
    <>
      {/* <CardSidebar p="15px" pl="20px">
        <form>
          <InputSearch />
        </form>
      </CardSidebar>*/}
      <CardSidebar>
        <TitleSidebar mb="28px">Categories</TitleSidebar>
        <CatList>
          <CatListItem link={"/contact-2"}>Contact Support</CatListItem>
          <CatListItem link={"/support-setup"}>Setup</CatListItem>
          <CatListItem link={"/support-vehicles"}>Vehicles</CatListItem>
          <CatListItem link={"/support-contracts"}>Contracts</CatListItem>
          <CatListItem link={"/support-customers"} className="mb-0">
            Customers
          </CatListItem>
        </CatList>
      </CardSidebar>
      <CardSidebar>
        <TitleSidebar>Top Posts</TitleSidebar>
        <Block>
          <TitlePost link={"/support-add-vehicle"}>
            Add A Vehicle In LoanerHub Quick
          </TitlePost>
          <Date>Feb 14, 2022</Date>
        </Block>
        <Block borderBottom="none" pb="0">
          <TitlePost link={"/support-new-contract"}>
            Create A New Contract
          </TitlePost>
          <Date>Feb 14, 2022</Date>
        </Block>
      </CardSidebar>
    </>
  );
};
export default Sidebar;
